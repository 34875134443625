import colors from 'constants/colors';
import fontSizes from 'constants/fontSizes';
import styled from 'styled-components';

export const Container = styled.div`

`;

export const InnerContent = styled.div<{
  orientation?: 'column' | 'row';
}>`
  width: fit-content;
  display: flex;
  flex-direction: ${(props) => props.orientation || 'column'};
  justify-content: center;
  gap: 3px;
`;

export const Value = styled.div`
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: normal;
`;

export const EmptyValue = styled.div`
  color: ${colors.gray850};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
`;

export const NoData = styled.div`
  color: ${colors.gray500};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const Infos = styled.div`
  color: ${colors.gray500};
  font-weight: 400;
  font-size: ${fontSizes.other.caption};
`;
